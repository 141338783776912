import PrimaryButton   from "_components/atoms/button/primaryButton";
import Icon            from "_components/atoms/icon";
import Render          from "_components/atoms/render/index.jsx";
import Typography      from "_components/atoms/typography";
import { STREAM_TIER } from "_components/pages/proPage/constants";

import FadeInOut from "../controls/fadeInOut";

import useStyles from "./styles";

const ProBar = ( {
	proExpiring,
	onClickProBar,
	isProUser,
	streamTier,
	streamId,
	showTimer,
	timeLeftInSeconds,
	inProp,
	finishedAd
} ) => {
	const { classes } = useStyles ( {}, { name: "proBarVideoPlayer" } );

	function getProTagText () {
		if ( isProUser ) {
			// if is pro user and pro is expiring
			if ( proExpiring ) {
				return "Pro Expiring";
			}

			// if pro is not expiring
			return "Pro";
		}

		// if user is not pro and stream is mid tier stream
		if ( streamTier === STREAM_TIER.MID_STREAM )
			return "Watch Ad";

		// if user is non pro and stream is regular stream
		return "Buy Pro";
	}

	return (
		<FadeInOut inProp = { inProp }>
			<div className = { classes.container }>
				<PrimaryButton
					buttonTextClassName = { classes.buyProButtonText }
					className = { classes.buyProButton }
					id = { `pro-bar-${ streamId }` }
					leftSection = { streamTier?.toString () === STREAM_TIER.PRO_STREAM ? (
						<Icon
							sameInBothTheme
							name = "proShineWhite"
							size = { 12 }
						/>
					) : undefined }
					onClick = { onClickProBar }
					rightSection = { !isProUser ? (
						<Icon
							modeType = { "dark" }
							name = "caret"
							size = { 14 }
						/>
					) : undefined }
					size = "small"
					title = { getProTagText () }
				/>

				<Render condition = { !proExpiring && showTimer && !isProUser && !finishedAd }>
					<Typography
						className = { classes.timerContainer }
						size = { "sm" }
						title = { timeLeftInSeconds }
					/>
				</Render>

			</div>
		</FadeInOut>
	);
};

export default ProBar;
